import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'focus:ring-0',
  variants: {
    colors: {
      default: `hover:bg-logga-400 focus:bg-logga-400 active:bg-logga-400 bg-gray-50 text-black active:shadow-[inset_0_0_4px_rgba(82,0,45,0.3)]`,
      text: `text-black`,
    },
    size: {
      small: `text-button-sm px-3 py-1.5`,
      medium: `text-button-sm px-3 py-2.25`,
      large: `text-button-md px-3.5 py-2`,
      text: `text-button-sm`,
    },
  },
});

export default Tag;
